<template>
  <section class="proposal-navigation-container">
    <div class="steps semibold-weight">
      <div v-for="(step, index) in steps" :key="step.page" class="step-container" @click="onSetPage(step.page, false)">
        <div class="step-number-container">
          <div :class="['separator-line', { 'invisible': index === 0 }]"></div>
          <div :class="['step-number bold-weight', { 'active': currentPage === step.page }]">
            {{ index + 1 }}
          </div>
          <div :class="['separator-line', { 'invisible': index === steps.length - 1 }]"></div>
        </div>

        <span :class="['step-label', { 'invisible': currentPage !== step.page, 'flex-center': currentPage === 'profiles' }]">{{ step.label }}</span>
      </div>
    </div>

    <div class="nav-buttons hidden-xs">
      <button type="button" @click="onChangePage('back', false)" :class="['nv-button-clear white-text', { disabled: currentPage === 'overview' }]">Back</button>

      <button type="button" @click="onChangePage('next', false)" :class="['nv-button-white no-border', { disabled: currentPage === 'review' }]">Next</button>
    </div>
  </section>
</template>

<script>
import { find } from 'lodash';

export default {
  name: 'StepInfo',

  props: {
    onSetPage: {
      type: Function,
      required: true
    },

    onChangePage: {
      type: Function,
      required: true
    },

    currentPage: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      steps: [
        { page: 'overview', label: 'Overview' },
        { page: 'profiles', label: 'Review Options' },
        { page: 'review', label: 'Next Steps' }
      ]
    };
  },

  computed: {
    currentStep() {
      return find(this.steps, { page: this.currentPage });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .proposal-navigation-container {
    @media (min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }

  .nv-button-clear {
    &:hover, &:active {
      background-color: rgba($white, .2);
    }
  }
</style>
