<template>
  <div :class="['proposal-nav-buttons', { 'row tight-columns': horizontal }]">
    <div :class="['bottom-10', { 'lawyer-nav-button-container': !horizontal, 'col-sm-4 col-md-3': horizontal }]" v-for="possibleLawyer in possibleLawyers" :key="possibleLawyer.id">
      <button type="button" :class="['nv-button-white-purple normal-weight', { 'active': possibleLawyer === selectedLawyer }]" @click="selectLawyer(possibleLawyer)">
        <div class="main-text size-text-16px text-ellipsis">
          {{ name(possibleLawyer) }}
        </div>

        <div class="normal-weight size-text-13px">
          <div class="top-5" v-if="!isLawFirm(possibleLawyer) && possibleLawyer.yearsExperience > 0">
            {{ possibleLawyer.yearsExperience }} years’ experience
          </div>

          <div class="top-5" v-if="isLawFirm(possibleLawyer)">
            <span v-if="!isAlsp(possibleLawyer)">{{ shouldShowSection(possibleLawyer, 'firm_size') && possibleLawyer.firmSize ? possibleLawyer.firmSize : 'Law Firm' }}</span>

            <span v-if="isAlsp(possibleLawyer)">Law/Tech Company</span>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProposalNavButtons',

  props: {
    possibleLawyers: {
      type: Array,
      required: true
    },

    selectedLawyer: {
      type: Object,
      default: () => ({})
    },

    selectLawyer: {
      type: Function,
      required: true
    },

    horizontal: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    name(possibleLawyer) {
      return this.isLawFirm(possibleLawyer) ? possibleLawyer.lawFirm.name : possibleLawyer.lawyer.fullName;
    },

    isLawFirm(possibleLawyer) {
      return !!possibleLawyer.lawFirm;
    },

    isAlsp(possibleLawyer) {
      return !!possibleLawyer.lawFirm.alspAccount;
    },

    shouldShowSection(possibleLawyer, section) {
      return possibleLawyer.displaySections.indexOf(section) >= 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  .proposal-nav-buttons {
    margin-left: -20px;
    margin-right: -20px;

    .lawyer-nav-button-container {
      padding: 0 20px;
    }
  }

  .nv-button-white-purple {
    text-align: left;
    height: 70px;
    text-transform: none;

    .main-text {
      line-height: 20px;
      text-transform: capitalize;
    }

    &.active {
      .main-text {
        font-weight: 600;
      }
    }
  }
</style>
