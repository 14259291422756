<template>
  <nav class="navbar proposal-nav primary-marketplace-gradient-90">
    <div class="navbar-content row">
      <div class="navbar-header col-sm-4">
        <a href="/" class="navbar-brand" target="_self"><svg-icon name="prioriLogo" class="brand-icon"></svg-icon></a>
      </div>

      <div class="project-name col-sm-8">
        {{ projectName }}
      </div>
    </div>
  </nav>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'NavbarProposal',

  components: {
    SvgIcon
  },

  props: {
    projectName: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .navbar.proposal-nav {
    border-radius: 0;
    box-shadow: none;
    color: $white;

    .navbar-content {
      display: block;
      text-align: center;

      @media (min-width: $screen-sm-min) {
        align-items: center;
        display: flex;
        margin: auto;
        max-width: 1140px;
        padding: 0 30px;
        text-align: unset;
      }

      @media (min-width: $screen-lg-min) {
        padding: 0;
      }
    }

    .navbar-brand {
      padding: 25px 0;
      width: 100%;

      .brand-icon {
        fill: $white;
      }
    }

    .navbar-header {
      padding: 10px 0;
    }

    .project-name {
      font-weight: 700;
      font-size: 24px;
      text-align: center;

      @media (min-width: $screen-sm-min) {
        text-align: right;
      }
    }
  }
</style>
