<template>
  <div class="profile-header-container">
    <proposal-lawyer-basic-details
      :possible-lawyer="possibleLawyer"
      :is-preview="true"
      :outside-the-gate="true"
      :is-marketplace-rfq="false">
    </proposal-lawyer-basic-details>

    <div class="profile-header-buttons">
      <div class="svg-button action-bar-button" @click="goToProposal">
        <svg-icon name="calendar" class="base-icon bottom-10"></svg-icon>
        <div>Schedule<br>Call</div>
      </div>

      <div class="svg-button action-bar-button" @click="goToProposal">
        <svg-icon name="envelope" class="base-icon bottom-10"></svg-icon>
        <div>Send<br>Message</div>
      </div>
    </div>
  </div>
</template>

<script>
import ProposalLawyerBasicDetails from 'vue-app/marketplace/client/proposals/proposal-lawyer-basic-details.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProposalHeader',

  components: {
    ProposalLawyerBasicDetails,
    SvgIcon
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    counselRequestId: {
      type: Number,
      required: true
    }
  },

  methods: {
    goToProposal() {
      window.open(`/client-app/requests/${this.counselRequestId}`, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .profile-header-container {
    padding-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      display: flex;
      column-gap: 10px;
      justify-content: space-between;
      margin-bottom: 30px;
      border-bottom: 1px solid $k-light-gray;
    }
  }

  .profile-header-buttons {
    display: flex;
    flex: 1;
    column-gap: 13px;

    @media (min-width: $screen-sm-min) {
      column-gap: 10px;
    }

    .action-bar-button {
      padding: 10px 29px;
      border: 1px solid $k-light-gray;
      border-radius: 6px;

      @media (min-width: $screen-sm-min) {
        flex: 1;
        padding: 10px;
      }
    }
  }
</style>
