<template>
  <div class="shadowed-box">
    <div class="box-content variable-padded">
      <div class="row">
        <div v-if="hasProjectName" class="col-sm-4 bottom-20">
          <div class="small-bold-header bottom-5">
            Project Name
          </div>

          <div>
            {{ proposal.projectName }}
          </div>
        </div>

        <div class="col-sm-4 bottom-20">
          <div class="small-bold-header bottom-5">
            Prepared For
          </div>

          <div>
            {{ preparedFor }}
          </div>
        </div>

        <div class="col-sm-4 bottom-20">
          <div class="small-bold-header bottom-5">
            Prepared On
          </div>

          <div>
            {{ sentAt || '&mdash;' }}
          </div>
        </div>
      </div>

      <hr class="kenny-hr">

      <div v-if="hasRfpDescription" class="top-40 bottom-40">
        <div class="small-bold-header bottom-5">
          Project Description
        </div>

        <div>
          <span class="simple-text">{{ rfpDescription }}</span>

          <a href="" class="semibold-weight" @click.prevent="toggleDescription" v-if="proposal.rfpDescription.length > 650">view {{ showFullDescription ? 'less' : 'more' }}</a>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="top-40 bottom-30">
        This proposal includes <span class="semibold-weight">{{ numOptions }}</span> from our vetted network that {{ possibleLawyers.length > 1 ? 'match' : 'matches' }} your project requirements.
      </div>

      <div class="row">
        <div class="col-sm-4 col-sm-offset-4">
          <button type="button" class="nv-button-blue a-button-size" @click="onSetPage('profiles', true)">Review Your Options</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';

export default {
  name: 'Overview',

  props: {
    proposal: {
      type: Object,
      required: true
    },

    onSetPage: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showFullDescription: false
    };
  },

  computed: {
    hasProjectName() {
      return this.proposal.projectName && this.proposal.projectName.length > 0;
    },

    hasRfpDescription() {
      return this.proposal.rfpDescription && this.proposal.rfpDescription.length > 0;
    },

    possibleLawyers() {
      return this.proposal.possibleLawyers;
    },

    numOptions() {
      return this.$pluralize('option', this.possibleLawyers.length, true);
    },

    rfpDescription() {
      return this.showFullDescription ? this.proposal.rfpDescription : this.proposal.rfpDescription.substring(0, 650);
    },

    preparedFor() {
      return `${this.proposal.forFirstName} ${this.proposal.forLastName}`;
    },

    sentAt() {
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return DateFilter.filter(this.proposal.sentAt, options);
    }
  },

  methods: {
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    }
  }
};
</script>
