<template>
  <div class="proposal-footer text-center">
    <svg-icon name="priori" class="base-icon"></svg-icon>
    <div class="top-20 bottom-20">
      Questions about your proposal? <a :href="'mailto:' + adminEmail.address + '?subject=' + adminEmail.subject" class="semibold-weight">Contact us</a> with any questions.
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProposalFooter',

  components: {
    SvgIcon
  },

  props: {
    adminEmail: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .proposal-footer {
    margin: 50px 0;
    padding: 0 20px;

    svg {
      fill: $grey-4;
    }
  }

  .base-icon {
    width: 50px;
    height: 50px;
  }
</style>
