<template>
  <div class="shadowed-box">
    <div class="box-content variable-padded size-text-13px">
      <div class="bottom-20">
        This proposal isn&rsquo;t active at the moment. {{ text }}
      </div>

      <div class="bottom-30">
        If you have any questions, please don&rsquo;t hesitate to <a :href="`mailto: ${adminEmail.address}?subject=${adminEmail.subject}`" class="semibold-weight">contact us</a>.
      </div>

      <div>Sincerely,</div>
      <div class="semibold-weight">
        The Priori Team
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Inactive',

  props: {
    text: String,
    adminEmail: Object
  }
};
</script>
