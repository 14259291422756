<template>
  <div>
    <navbar-proposal :project-name="proposal.projectName"></navbar-proposal>

    <div id="pageTop" class="proposal-header">
      <div class="section-wave-top primary-marketplace-gradient-90"></div>
      <div class="section-wave-bottom bottom-50"></div>
    </div>

    <div class="proposal-content-container">
      <div class="bottom-50">
        <step-info :on-set-page="setPage" :on-change-page="changePage" :current-page="currentPage"></step-info>
      </div>

      <div class="proposal-content">
        <div v-if="currentPage === 'inactive' || currentPage === 'closed'">
          <inactive :text="inactivePageText" :admin-email="adminEmail"></inactive>
        </div>

        <div v-if="currentPage === 'overview' || !currentPage">
          <overview :proposal="proposal" :on-set-page="setPage"></overview>
        </div>

        <div v-if="currentPage === 'profiles' && currentPage !== 'inactive' && currentPage !== 'closed'">
          <profiles :proposal="proposal" :selected-lawyer="selectedLawyer" :select-lawyer="selectLawyer"></profiles>
        </div>

        <div class="normal-weight size-text-13px" v-if="currentPage === 'review'">
          <review :proposal="proposal" :admin-email="adminEmail" :select-lawyer="selectLawyer"></review>
        </div>
      </div>

      <div class="nav-buttons top-30 hidden-sm hidden-md hidden-lg">
        <button type="button" :class="['nv-button-clear-blue', { disabled: currentPage === 'overview' }]" @click="changePage('back', true)">Back</button>

        <button type="button" :class="['nv-button-blue', { disabled: currentPage === 'review' }]" @click="changePage('next', true)">Next</button>
      </div>
    </div>

    <proposal-footer :admin-email="adminEmail"></proposal-footer>
  </div>
</template>

<script>
import NavbarProposal from 'vue-app/marketplace/client/proposals/navbar-proposal.vue';
import StepInfo from 'vue-app/marketplace/client/proposals/step-info.vue';
import Inactive from 'vue-app/marketplace/client/proposals/pages/inactive.vue';
import Overview from 'vue-app/marketplace/client/proposals/pages/overview.vue';
import Profiles from 'vue-app/marketplace/client/proposals/pages/profiles.vue';
import Review from 'vue-app/marketplace/client/proposals/pages/review.vue';
import ProposalFooter from 'vue-app/marketplace/client/proposals/proposal-footer.vue';
import Proposal from 'resources/marketplace/proposal.js';
import DateFilter from 'vue-app/shared/lib/date-filter';
import { indexOf } from 'lodash';

export default {
  name: 'ProposalsShow',

  components: {
    NavbarProposal,
    StepInfo,
    Inactive,
    Overview,
    Profiles,
    Review,
    ProposalFooter
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      proposal: this.initData.proposal,
      currentPage: this.initData.proposal.possibleLawyers.length ? 'overview' : 'inactive',
      steps: ['overview', 'profiles', 'review'],
      selectedLawyer: this.initData.proposal.possibleLawyers[0]
    };
  },

  computed: {
    adminEmail() {
      return {
        address: this.proposal.proposalManagerEmail,
        subject: 'Re: Priori Proposal ' + (this.nameAndTitle.length ? ('For ' + this.nameAndTitle) : ('#' + this.proposal.id))
      };
    },

    inactivePageText() {
      if (this.currentPage === 'inactive') {
        return 'It will be activated as soon as we have suggested options for your legal project.';
      }

      return 'Please begin a new RFP or contact our client team if you would like to reactivate this proposal.';
    },

    updatedAt() {
      return DateFilter.filter(this.proposal.updatedAt);
    },

    nameAndTitle() {
      const name = [];
      const result = [];

      if (this.proposal.forFirstName && this.proposal.forFirstName.length) {
        name.push(this.proposal.forFirstName);
      }
      if (this.proposal.forLastName && this.proposal.forLastName.length) {
        name.push(this.proposal.forLastName);
      }
      if (name.length) {
        result.push(name.join(' '));
      }
      if (this.proposal.forTitle && this.proposal.forTitle.length) {
        result.push(this.proposal.forTitle);
      }

      return result.join(', ');
    },

    forCompany() {
      return this.proposal.forCompany || '';
    }
  },

  created() {
    this.setDefaultPage();
  },

  mounted() {
    Proposal.track({ id: this.initData.proposal.id });
  },

  methods: {
    selectLawyer(lawyer) {
      this.selectedLawyer = lawyer;
      this.setPage('profiles', true);
    },

    setDefaultPage() {
      if (this.proposal.status === 'admin_closed') {
        this.currentPage = 'closed';
      }
      else if (this.proposal.possibleLawyers.length === 0) {
        this.currentPage = 'inactive';
      }
      else {
        this.currentPage = 'overview';
      }
    },

    autoScroll() {
      document.getElementById('pageTop').scrollIntoView({ block: 'start', behavior: 'smooth' });
    },

    setPage(pageName, scroll) {
      this.currentPage = pageName;

      if (scroll) {
        this.autoScroll();
      }
    },

    changePage(direction, scroll) {
      const offset = direction === 'back' ? -1 : 1;
      const stepIndex = indexOf(this.steps, this.currentPage) + offset;
      this.setPage(this.steps[stepIndex], scroll);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .section-wave-top {
    margin-top: -1px;
    padding: 42px 10px 0;
    color: $white;
    text-align: center;
    height: 200px;

    @media (min-width: $screen-sm-min) {
      padding: 42px 0 0;
      height: 140px;
    }
  }

  .section-wave-bottom {
    height: 175px;
    margin-top: -4px;
    background-image: url("images/wave_header_bottom.svg");
    background-size: 100% 100%;

    @media (min-width: $screen-md-min) {
      height: 250px;
    }
  }
</style>
