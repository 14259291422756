<template>
  <section>
    <div class="shadowed-box size-text-13px hidden-xs proposal-desktop-view">
      <div id="sectionTop" class="row">
        <div class="col-sm-4 box-content variable-less-padded">
          <proposal-nav-buttons
            :possible-lawyers="possibleLawyers"
            :selected-lawyer="selectedLawyer"
            :select-lawyer="selectLawyer">
          </proposal-nav-buttons>
        </div>

        <div class="col-sm-8 box-content variable-less-padded">
          <proposal-header
            :possible-lawyer="selectedLawyer"
            :counsel-request-id="proposal.counselRequestId">
          </proposal-header>

          <proposal-lawyer-details
            :possible-lawyer="selectedLawyer"
            :is-marketplace-rfq="false">
          </proposal-lawyer-details>
        </div>
      </div>
    </div>

    <div class="hidden-sm hidden-md hidden-lg" v-for="(possibleLawyer, index) in possibleLawyers" :key="possibleLawyer.id">
      <div class="shadowed-box bottom-10">
        <div :class="['box-content variable-less-padded accordion-toggle', { 'active': showAccordion[index] }]" @click.prevent="toggleAccordion(index)">
          <div>
            <div class="size-text-16px semibold-weight">
              {{ optionName(possibleLawyer) }}
            </div>

            <div class="size-text-13px">
              <div v-if="!isLawFirm(possibleLawyer)">
                {{ possibleLawyer.yearsExperience }} years experience
              </div>

              <div v-if="isLawFirm(possibleLawyer)">
                <span v-if="!isAlsp(possibleLawyer)">{{ shouldShowSection(possibleLawyer, 'firm_size') && possibleLawyer.firmSize ? possibleLawyer.firmSize : 'Law Firm' }}</span>

                <span v-if="isAlsp(possibleLawyer)">Law/Tech Company</span>
              </div>
            </div>
          </div>

          <svg-icon :name="`caret-${showAccordion[index] ? 'up' : 'down'}`" class="base-icon"></svg-icon>
        </div>

        <collapse v-model="showAccordion[index]" :transition="100">
          <div id="sectionTop" class="row size-text-13px">
            <div class="box-content variable-less-padded">
              <proposal-header
                :possible-lawyer="possibleLawyer"
                :counsel-request-id="proposal.counselRequestId">
              </proposal-header>

              <proposal-lawyer-details
                :possible-lawyer="possibleLawyer"
                :is-marketplace-rfq="false">
              </proposal-lawyer-details>
            </div>
          </div>
        </collapse>
      </div>
    </div>
  </section>
</template>

<script>
import ProposalHeader from 'vue-app/marketplace/client/proposals/proposal-header.vue';
import ProposalLawyerDetails from 'vue-app/marketplace/client/proposals/proposal-lawyer-details.vue';
import ProposalNavButtons from 'vue-app/marketplace/client/proposals/proposal-nav-buttons.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'Profiles',

  components: {
    ProposalHeader,
    ProposalLawyerDetails,
    ProposalNavButtons,
    SvgIcon
  },

  props: {
    proposal: {
      type: Object,
      required: true
    },

    selectedLawyer: {
      type: Object,
      required: true
    },

    selectLawyer: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showAccordion: new Array(this.proposal.possibleLawyers.length).fill(false)
    };
  },

  computed: {
    possibleLawyers() {
      return this.proposal.possibleLawyers;
    }
  },

  methods: {
    optionName(possibleLawyer) {
      return possibleLawyer.lawFirm?.name || possibleLawyer.lawyer.fullName;
    },

    isLawFirm(possibleLawyer) {
      return !!possibleLawyer.lawFirm;
    },

    isAlsp(possibleLawyer) {
      return !!possibleLawyer.lawFirm.alspAccount;
    },

    shouldShowSection(possibleLawyer, section) {
      return possibleLawyer.displaySections.indexOf(section) >= 0;
    },

    toggleAccordion(index) {
      if (this.showAccordion[index]) {
        this.$set(this.showAccordion, index, false);
      }
      else {
        this.showAccordion = this.showAccordion.map((_v, i) => i === index);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .accordion-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.active {
      border-bottom: 1px solid $k-light-gray;
    }
  }
</style>
