<template>
  <div class="shadowed-box">
    <div class="box-content variable-padded">
      <div class="bottom-40">
        <div v-if="isRequestOwner || isAdmin">
          <div class="size-text-16px bottom-40">
            You have now viewed the summaries of your options&rsquo; experience and pricing. <a :href="'/client-app/requests/' + proposal.counselRequestId" class="semibold-weight" target="_blank">Sign in</a> to your Priori account to complete the steps below and view more details about the suggested providers.
          </div>

          <next-steps :proposal="proposal"></next-steps>
        </div>

        <div v-if="isSharedView && !isRequestOwner && !isAdmin" class="size-text-16px">
          You have now reviewed all of your suggested options. To schedule introductory calls with any of the providers, please <a :href="'mailto:' + adminEmail.address + '?subject=' + adminEmail.subject" class="semibold-weight">message your Project Manager</a>. You can also download a PDF copy of the proposal <a :href="downloadProposalLink" target="_blank">here</a>.
        </div>
      </div>

      <div class="row bottom-40">
        <div class="col-sm-4 col-sm-offset-4 col-md-2 col-md-offset-5">
          <a :href="'/client-app/requests/' + proposal.counselRequestId" target="_blank" class="nv-button-blue smaller">Sign in</a>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="small-bold-header bottom-15">
        Review your options again
      </div>

      <proposal-nav-buttons
        :possible-lawyers="proposal.possibleLawyers"
        :select-lawyer="selectLawyer"
        :horizontal="true">
      </proposal-nav-buttons>
    </div>
  </div>
</template>

<script>
import NextSteps from 'vue-app/marketplace/client/proposals/next-steps.vue';
import ProposalNavButtons from 'vue-app/marketplace/client/proposals/proposal-nav-buttons.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { indexOf } from 'lodash';

export default {
  name: 'Review',

  components: {
    NextSteps,
    ProposalNavButtons
  },

  props: {
    proposal: {
      type: Object,
      required: true
    },

    adminEmail: {
      type: Object,
      required: true
    },

    selectLawyer: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    isRequestOwner() {
      const user = this.currentClient;
      return user && (user.id === this.proposal.requesterId) && (user.klass === 'Client') && (this.proposal.requesterType === 'Client');
    },

    isAdmin() {
      return this.currentClient && (this.currentClient.klass === 'AdminUser');
    },

    isSharedView() {
      return !!this.token;
    },

    token() {
      const split_location = window.location.pathname.split('/');
      return split_location[indexOf(split_location, 'shared') + 1];
    },

    downloadProposalLink() {
      if (this.isSharedView) {
        return '/proposals/' + this.proposal.id + '.pdf?token=' + this.token;
      }
      else {
        return '/proposals/' + this.proposal.id + '.pdf';
      }
    }
  }
};
</script>
